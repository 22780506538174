import "./App.css";
import React, { useState } from "react";
import { FaFlag } from "react-icons/fa";

function App() {
  const [aboutMe, setAboutMe] = useState(false);
  const [purpose, setPurpose] = useState(false);
  const [redFlags, setRedFlags] = useState(false);
  const [greenFlags, setGreenFlags] = useState(false);

  return (
    <div className="flex flex-col items-center bg-black min-h-screen text-[2.2rem] md:text-[2.5rem] [&>*]:my-10">
      {!purpose && !redFlags && !greenFlags && (
        <div
          className="flex flex-col items-center justify-center w-[50rem] md:w-[60rem] min-h-[20rem] h-auto p-5 border border-yellow-400 rounded-2xl hover:cursor-pointer shadow-yellow-400/50 shadow-xl hover:shadow-yellow-400"
          onClick={() => {
            setAboutMe(!aboutMe);
            setPurpose(false);
            setRedFlags(false);
            setGreenFlags(false);
          }}>
          {aboutMe ? (
            <div className="text-[2rem] flex flex-col text-yellow-600 [&>*]:my-1">
              <p>
                Považuju se za uzavřenýho intelektuála, co si dost bedlivě střeží svoje soukromí.
                Stačí mi do života pár přátel a navazování novejch vztahů na jakýkoliv úrovni je pro
                mě noční můra. Nicméně dost často překračuju svoji komfortní zónu a po určitý době
                si každej člověk, co mi za to stojí, získá moji důvěru. Mám tendenci cpát logiku
                téměř do všeho, i tam, kam nepatří. Jsem taky zásadovej až za hrob, jsou určitý
                povahový vlastnosti, který u lidí nemůžu vystát, proto jsem rád sám.
              </p>
              <p>
                Jsem dost upřímnej a říkám věci na férovku, což občas může někoho urazit - když
                někoho nemám rád, hned to ten člověk pozná z výrazu tváře a způsobu komunikace
                (jedinou výjimku udělám, když se jedná třeba o nadřízenýho v práci, kterýho si
                nechci znepřátelit). Z tohohle důvodu očekávám podobnej přístup od ostatních a dost
                špatně snášim přetvářky a lži, což je hlavní důvod proč si s většinou lidí
                nerozumím.
              </p>
              <p>
                Miluju sarkasmus a černej humor bez hranic, politická korektnost je mi naprosto
                cizí. Smích je koření života a humor by se neměl vůbec omezovat, dokud na někoho
                vyloženě neútočí a nehází špínu (pak už to není humor).
              </p>
              <p>
                Mám dost silnej vztah k hudbě a tendenci tak trochu soudit lidi podle toho, co
                poslouchaj, protože mám z osobních zkušeností vypozorovaný, že to o nich dost
                vypovídá. To zdaleka neznamená, že musíme mít stejnej vkus, já dokážu ocenit i
                interprety a žánry, co sám neposlouchám, dokud tam jsou smysluplný texty nebo hezká
                melodie (což u dnešní hudební scény bohužel není samozřejmost).
              </p>
              <p>
                Ve svym volnym čase dělám spoustu různejch věcí. Momentálně se nejvíc věnuju
                programování, volejbalu a hře na kytaru. Odmalička hraju závodně šachy a teď jsem
                se k nim po delší době vrátil, ale už na ně nemám tolik času. Hrozně rád cestuju, 4
                roky jsem žil v zahraničí a do třicítky bych chtěl procestovat svět. Taky rád vařím
                a peču, baví mě zkoušet nový recepty a experimentovat. Dřív jsem se věnoval i
                tvůrčímu psaní, napsal jsem pár nedokončenejch knížek a sci-fi příběhů. Občas se i
                podívám na kvalitní seriál nebo si přečtu zajímavou knížku. V nejbližší době plánuju
                přidat si další sport a začít pravidelně chodit do přírody, akorát mě to samotnýho
                moc nebaví, proto si chci rozšířit okruh přátel.
              </p>
            </div>
          ) : (
            <h1 className="text-yellow-400">Něco o mně</h1>
          )}
        </div>
      )}
      {!aboutMe && !redFlags && !greenFlags && (
        <div
          className="flex flex-col items-center justify-center w-[50rem] md:w-[60rem] min-h-[20rem] h-auto p-5 border border-yellow-400 rounded-2xl hover:cursor-pointer shadow-yellow-400/50 shadow-xl hover:shadow-yellow-400"
          onClick={() => {
            setPurpose(!purpose);
            setAboutMe(false);
            setRedFlags(false);
            setGreenFlags(false);
          }}>
          {purpose ? (
            <div className="text-[2rem] flex flex-col text-yellow-600 [&>*]:my-1">
              <p>
                Tak samozřejmě by byl ideální spokojenej a dlouhodobej vztah, ale nedělám si iluze.
                Tyhle typy seznamek jsou postavený na příšerně debilnim principu swipe left/right.
                Já podle fotek fakt nepoznám, jestli se mi někdo líbí, nebo ne. Sice mi taky záleží
                na tom, jak vypadáš, ale zdaleka to není to nejdůležitější (navíc v dnešní době
                filtrů fotky kolikrát neodpovídají reálnýmu vzhledu). Navíc o sobě většinou nikdo
                nic nenapíše...
              </p>
              <p>
                Každopádně vztah nikomu nespadne do klína a musí se na něm aktivně pracovat. Pokud
                tu nehledáš nic hlubšího a nejseš ochotná vyvinout adekvátní snahu, tak mi to dej
                hned vědět. Už jsem tu ztratil dost času, kterej bych radši využil smysluplnějc a
                rád bych, aby se mi tu časová a do určitý míry v některejch případech i emocionální
                investice vyplatila...
              </p>
              <p>
                Nějakou dobu mi trvá, než k někomu začnu něco cítit, ale pokud tam vidím potenciál a
                oboustrannej zájem, tak vyvinu snahu a je to jen otázka času. Je sice jen málo lidí,
                na kterejch mi v životě opravdu záleží, ale o to víc si jich pak vážím a klidně bych
                se pro ně rozkrájel.
              </p>
            </div>
          ) : (
            <h1 className="text-yellow-400">Co tady vlastně hledám?</h1>
          )}
        </div>
      )}
      {!aboutMe && !purpose && !greenFlags && (
        <div
          className="flex flex-col items-center justify-center w-[50rem] md:w-[60rem] min-h-[20rem] h-auto p-5 border border-yellow-400 rounded-2xl hover:cursor-pointer shadow-yellow-400/50 shadow-xl hover:shadow-yellow-400"
          onClick={() => {
            setRedFlags(!redFlags);
            setAboutMe(false);
            setPurpose(false);
            setGreenFlags(false);
          }}>
          {redFlags ? (
            <div className="text-[1.7rem] flex flex-col text-yellow-600 [&>*]:my-5">
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-red-600 font-bold">
                  <FaFlag className="text-red-600 mr-5" />
                  <h1>Seš závislá na sociálních sítích</h1>
                </div>
                <p className="mx-5 mt-5">
                  Tak to je docela problém. Já nemám potřebu nikomu cpát svůj osobní život. Kdysi
                  jsem měl víc sociálních sítí, ale teď už používám jenom Facebook, Messenger a
                  WhatsApp. Jestli si nedokážeš užívat život v přítomnosti a máš potřebu neustále
                  zasypávat svůj profil selfíčkama a storíčkama, tak ti zaručuju, že si rozhodně
                  rozumět nebudeme.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-red-600 font-bold">
                  <FaFlag className="text-red-600 mr-5" />
                  <h1>Vystrčená prdel na profilovce</h1>
                </div>
                <p className="mx-5 mt-5">
                  Proč ne radši hezkej úsměv? Ne, fakt nejseš zajímavá. Na to jsou jiný stránky
                  18+... V mym věku se mi vzhledově líbí tak 70% holek, takže jestli nedokážeš
                  zaujmout ničim jinym než svojí postavou, tak mi seš úplně k ničemu.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-red-600 font-bold">
                  <FaFlag className="text-red-600 mr-5" />
                  <h1>Vymetáš kluby</h1>
                </div>
                <p className="mx-5 mt-5">
                  To je jeden z mála podniků, kterejm se vyhýbám jako čert kříži. O lidech, co tam
                  pravidelně chodí, tak to taky něco vypovídá, v tomhle případě to, že nejsi můj
                  typ.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-red-600 font-bold">
                  <FaFlag className="text-red-600 mr-5" />
                  <h1>Seš zlatokopka</h1>
                </div>
                <p className="mx-5 mt-5">
                  Ne, fakt tě nebudu živit. Vztah má bejt o tom, že spolu dva tráví čas, ne o
                  penězích. Nemám se špatně a jsem ochotnej se o spoustu věcí dělit, ale musíš si to
                  zasloužit.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-red-600 font-bold">
                  <FaFlag className="text-red-600 mr-5" />
                  <h1>Seš manipulativní</h1>
                </div>
                <p className="mx-5 mt-5">
                  Tohle jsem dal úplně dospodu, protože s tím se dá pracovat, nicméně mě to dokáže
                  dost vytočit. Emociální vydírání maj v sobě všechny ženský zakódovaný, některá
                  víc, některá míň. Já jsem vůči tomu naštěstí díky nízký emocionální inteligenci
                  imunní a dokážu to snadno identifikovat, takže mi zas tak nevadí, když to nebudeš
                  dělat naschvál.
                </p>
              </div>
            </div>
          ) : (
            <h1 className="text-yellow-400 flex items-center">
              <span>Nebudeme si rozumět, pokud...</span>{" "}
              <FaFlag className="text-red-600 animate-pulse ml-5" />
            </h1>
          )}
        </div>
      )}
      {!aboutMe && !purpose && !redFlags && (
        <div
          className="flex flex-col items-center justify-center w-[50rem] md:w-[60rem] min-h-[20rem] h-auto p-5 border border-yellow-400 rounded-2xl hover:cursor-pointer shadow-yellow-400/50 shadow-xl hover:shadow-yellow-400"
          onClick={() => {
            setGreenFlags(!greenFlags);
            setAboutMe(false);
            setPurpose(false);
            setRedFlags(false);
          }}>
          {greenFlags ? (
            <div className="text-[1.7rem] flex flex-col text-yellow-600 [&>*]:my-5">
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-green-600 font-bold">
                  <FaFlag className="text-green-600 mr-5" />
                  <h1>Seš samostatná a nezávislá</h1>
                </div>
                <p className="mx-5 mt-5">
                  Pecka. Nemůžeme spolu bejt 24/7. Jsem samotář a potřebuju taky chvilku času jenom
                  pro sebe, takže pokud máš tendenci bejt citově závislá, tak to asi fungovat
                  nebude.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-green-600 font-bold">
                  <FaFlag className="text-green-600 mr-5" />
                  <h1>Máš smysl pro humor a nic tě neurazí</h1>
                </div>
                <p className="mx-5 mt-5">
                  To je u dnešní "snowflake" generace velmi vzácný a já si toho ohromně cením. Lidi,
                  co si umí ze sebe vystřelit, jsou poklad.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-green-600 font-bold">
                  <FaFlag className="text-green-600 mr-5" />
                  <h1>Ráda cestuješ</h1>
                </div>
                <p className="mx-5 mt-5">
                  Paráda. Pokud si nikoho nenajdu, nevadí mi cestovat sám, ale ve dvou je to
                  příjemnější.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-green-600 font-bold">
                  <FaFlag className="text-green-600 mr-5" />
                  <h1>Umíš vařit, nebo tě to aspoň baví</h1>
                </div>
                <p className="mx-5 mt-5">
                  Neboj, nechci abys mi vařila, to zvládnu sám. Každopádně je to něco, co by měl
                  umět každej člověk schopnej samostatně žít a zároveň se nestravovat svinstvem.
                  Navíc se jedná o skvělou párovou aktivitu.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1.9rem] md:text-[2.2rem] flex items-center border-b border-green-600 font-bold">
                  <FaFlag className="text-green-600 mr-5" />
                  <h1>Seš upřímná</h1>
                </div>
                <p className="mx-5 mt-5">
                  Za svůj život jsem potkal asi jenom dvě opravdu upřímný slečny, z nichž jedna byla
                  lesba, takže tohle by byl nejspíš zázrak. Každopádně komunikace je v jakymkoliv
                  vztahu naprostej základ a já jsem mnohem radši, když mě někdo na férovku pošle do
                  prdele, nebo řekne jakkoliv nepříjemnou pravdu místo mazání medu kolem huby nebo
                  zatajování a lhaní. A jo, to že budeš sprostá, mě neodrazuje, pokud nemluvíš jako
                  úplnej dlaždič. Vulgarismus je stejně jen sociální konstrukt...
                </p>
              </div>
            </div>
          ) : (
            <h1 className="text-yellow-400 flex items-center">
              <span>Budeme si rozumět, pokud...</span>{" "}
              <FaFlag className="text-green-600 animate-pulse ml-5" />
            </h1>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
